import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Menu.css'

const Menu = ({ data }) => {
    const extractedData = data.map((product) => ({
        id: product.id,
        name: product.name,
        image: product.image,
    }));

    const SimpleProductDiv = ({ id, name, image }) => (
        <div className="simple-product-container">
            <Link to={`/products/${id}`} className="simple-product-link">
                <div key={id} className="simple-product-container">
                    <p className="product-name">{name}</p>
                    <img className="product-image" src={image} alt={name} />
                </div>
            </Link>
        </div>
    );


    const SimpleProductsList = ({ extractedData }) => (
        <div className="simple-products-list">
            {extractedData.map((product) => (
                <SimpleProductDiv key={product.id} {...product} />
            ))}
        </div>
    );


    return (
        <div className="menu-container">
            <div class="titleee">
                <div className='tit'>
                <h1 class="title">CALCULADORA NUTRICIONAL</h1>
                <h3 class="children-title">(HdeC,Prot.,Grs.,Cal,Ca,Fe,Vit.A,Vit.C)</h3>
                <p class="title-elements">Alimentos segun la tabla de composicion quimica promedio</p>
                </div>
                <SimpleProductsList extractedData={extractedData} />
            </div>
        </div>
    );
};

export default Menu;