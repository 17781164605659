import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Navbar.css';

const Navbar = () => {
    
    return (
        <nav className={`navbar`}>
            <div className="navbar">
                <div className="navbar-links">
                    <Link to="/">Inicio</Link>
                </div>
                <div className="navbar-brand">
                    <a href='https://portafoliodemateo.website/'>Contactame</a>
                    <a href='https://cafecito.app/mateoschallerfranich'>Colabora</a>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;