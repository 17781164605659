import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../css/ProductDetails.css'
const ProductDetail = ({ products }) => {
    const { productId } = useParams();
    const [result, setResult] = useState(null);
    const [inputValue, setInputValue] = useState('');
    useEffect(() => {
        // Desplazar hacia arriba cuando el componente se monta
        window.scrollTo(0, 0);
    }, []);  // La dependencia está vacía para que se ejecute solo una vez al montar el componente

    const product = products.find((product) => product.id === parseInt(productId, 10));
    if (!product) {
        return <p>Producto no encontrado</p>;
    }

    const calculateValues = (product) => {
        if (isNaN(inputValue)) {
            alert('Ingrese un número válido');
        } else {
            const gramos = parseFloat(inputValue);
            const HDC = (gramos * product.hdc) / 100;
            const PROT = (gramos * product.proteinas) / 100;
            const GRAS = (gramos * product.grasas) / 100;
            const CA = (gramos * product.calcio) / 100;
            const FE = (gramos * product.hierro) / 100;
            const V_A = (gramos * product.vitaminaa) / 100;
            const V_C = (gramos * product.vitaminc) / 100;

            const CA2 = CA.toFixed(2);
            const FE2 = FE.toFixed(2);
            const V_A2 = V_A.toFixed(2);
            const V_C2 = V_C.toFixed(2);
            const GRAS2 = GRAS.toFixed(2);
            const HDC2 = HDC.toFixed(2);
            const PROT2 = PROT.toFixed(2);
            const HDC3 = HDC2 * 4;
            const GRAS3 = GRAS2 * 9;
            const PROT3 = PROT2 * 4;
            const CAL = GRAS3 + PROT3 + HDC3;
            const CAL2 = CAL.toFixed(2);

            const newResult = (
                <div className="resultadoini">
                    <p className="text-result">Valores en {gramos} g</p>
                    <div className="inicial">
                        <table className="tableas">
                            <tr>
                                <td>{product.name}</td>
                                <td>{gramos} g</td>
                            </tr>
                            <tr>
                                <td>Hidratos de carbono</td>
                                <td>{HDC2} g</td>
                            </tr>
                            <tr>
                                <td>Proteina</td>
                                <td>{PROT2} g</td>
                            </tr>
                            <tr>
                                <td>Grasas</td>
                                <td>{GRAS2} g</td>
                            </tr>
                            <tr>
                                <td>Calorias</td>
                                <td>{CAL2} cal</td>
                            </tr>
                            <tr>
                                <td>Calcio</td>
                                <td>{CA2} mg</td>
                            </tr>
                            <tr>
                                <td>Hierro</td>
                                <td>{FE2} mg</td>
                            </tr>
                            <tr>
                                <td>Vitamina A</td>
                                <td>{V_A2} U.I</td>
                            </tr>
                            <tr>
                                <td>Vitamina C</td>
                                <td>{V_C2} mg</td>
                            </tr>
                        </table>
                    </div>
                </div>
            );

            setResult(newResult);
        }
    };


    return (
        <div className='padree'>
        <div className='cf'>
            <div class="titleeee">
                <h1 class="titless">CALCULADORA NUTRICIONAL</h1>
                <h3 class="children-titlesd">(HdeC,Prot.,Grs.,Cal,Ca,Fe,Vit.A,Vit.C)</h3>
                <p class="title-elementssd">Alimentos segun la tabla de composicion quimica promedio</p>
                <h2 className='names'>{product.name}</h2>
            </div>
            <div className='principaña'>
                <p className="text-inputasd">Ingrese el peso neto en gramos</p>
                <input
                    className="inputqq"
                    placeholder="Ingrese el peso neto..."
                    type="text"
                    name="peso"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
                <button className="buttonqq" type="button" onClick={() => calculateValues(product)}>
                    Enviar
                </button>
            </div>

            {result}
            <div result-title className='resultadoini'>
                <p class="text-resultss">Valores inciales</p>
                <div class="inicial">
                    <table class="tableas">
                        <tr>
                            <td>{product.name}</td>
                            <td>100 g</td>
                        </tr>
                        <tr>
                            <td>Hidratos de carbono</td>
                            <td>{product.hdc} g</td>
                        </tr>
                        <tr>
                            <td>Proteina</td>
                            <td>{product.proteinas} g</td>
                        </tr>
                        <tr>
                            <td>Grasas</td>
                            <td>{product.grasas} g</td>
                        </tr>
                        <tr>
                            <td>Calcio</td>
                            <td>{product.calcio} mg</td>
                        </tr>
                        <tr>
                            <td>Hierro</td>
                            <td>{product.hierro} mg</td>
                        </tr>
                        <tr>
                            <td>Vitamina A</td>
                            <td>{product.vitaminaa} U.I</td>
                        </tr>
                        <tr>
                            <td>Vitamina C</td>
                            <td>{product.vitaminc} mg</td>
                        </tr>

                    </table>
                </div>
            </div>
            
        </div>
        </div>
    );
};

export default ProductDetail;