import leche from './images/leche.png'
import leche_par_desc from './images/leche_par_desc.png'
import leche_desc from './images/leche_desc.png'
import yogur from './images/yogur.png'
import quesos_1 from './images/queso_1.png'
import quesos_2 from './images/queso_2.png'
import quesos_3 from './images/queso_3.png'
import quesos_4 from './images/queso_4.png'
import queso_unt_desc from './images/queso_unt_desc.png'
import queso_blan_desc from './images/queso_blan_desc.png'
import huevo from './images/huevo.png'
import vaca from './images/vaca.png'
import cerdo from './images/cerdo.png'
import pollo from './images/pollo.png'
import pesacado from './images/pesacado.png'
import jamon from './images/jamon.png'
import ver_a from './images/ver_a.png'
import ver_b from './images/ver_b.png'
import ver_c from './images/ver_c.png'
import frutas_frescas from './images/frutas_frescas.png'
import frutas_desecadas from './images/frutas_desecadas.png'
import frutas_secas from './images/frutas_secas.png'
import cereales from './images/cereales.png'
import legumbres from './images/legumbres.png'
import pan_frances from './images/pan_frances.png'
import facturas from './images/facturas.png'
import galletas from './images/galletas.png'
import azucar from './images/azucar.png'
import dulces from './images/dulces.png'
import aceite from './images/aceite.png'
import manteca from './images/manteca.png'
import crema_liv from './images/crema_liv.png'
import crema from './images/crema.png'

const imagess = [
    leche,
    leche_par_desc,
    leche_desc,
    yogur,
    quesos_1,
    quesos_2,
    quesos_3,
    quesos_4,
    queso_unt_desc,
    queso_blan_desc,
    huevo,
    vaca,
    cerdo,
    pollo,
    pesacado,
    jamon,
    ver_a,
    ver_b,
    ver_c,
    frutas_frescas,
    frutas_desecadas,
    frutas_secas,
    cereales,
    legumbres,
    pan_frances,
    facturas,
    galletas,
    azucar,
    dulces,
    aceite,
    manteca,
    crema_liv,
    crema,
];

export default imagess;