import './css/App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './js/Navbar';
import Menu from './js/Menu';
import ProductDetail from './js/ProductDetail';
import imagess from './assets/images'
function App() {
  const dataa = [
    { id: 1, name: 'Leche', image: imagess[0], hdc: 5, proteinas: 3, grasas: 3, calcio: 120, hierro: 0.1,  vitaminaa: 295,   vitaminc: 1 },

    { id: 2, name: 'Leche parcialmente descremada', image: imagess[1], hdc: 4.5, proteinas: 4.1, grasas: 1.5, calcio: 157, hierro: 0, vitaminaa: 75, vitaminc: 0 },

    { id: 3, name: 'Leche descremada', image: imagess[2], hdc: 4.2, proteinas: 3, grasas: 0.1, calcio: 142, hierro: 0, vitaminaa: 300,vitaminc: 0 },

    { id: 4, name: 'Yogur', image: imagess[3], hdc: 4.5, proteinas: 4, grasas: 3,  calcio: 136, hierro: 0.1,  vitaminaa: 160,    vitaminc: 0 },

    { id: 5, name: 'Quesos 1', image: imagess[4], hdc: 4, proteinas: 14, grasas: 12, calcio: 75, hierro: 0.1,  vitaminaa: 1300,    vitaminc: 0 },

    { id: 6, name: 'Quesos 2', image: imagess[5], hdc: 2.3, proteinas: 20, grasas: 20,  calcio: 600, hierro: 0,  vitaminaa: 2000,    vitaminc: '0' },

    { id: 7, name: 'Quesos 3', image: imagess[6], hdc: 0, proteinas: 25, grasas: 28, calcio: 890, hierro: 0,  vitaminaa: 3600,   vitaminc: 0 },

    { id: 8, name: 'Quesos 4', image: imagess[7], hdc: 0, proteinas: 33, grasas: 29, calcio: 1100, hierro: 0,  vitaminaa: 1060,   vitaminc: 0 },

    { id: 9, name: 'Quesos untables descremados', image: imagess[8], hdc: 2.7, proteinas: 13, grasas: 12, calcio: 933.3, hierro: 0,  vitaminaa: 989,    vitaminc: 0 },

    { id: 10, name: 'Quesos blandos descremados', image: imagess[9], hdc: 0, proteinas: 32, grasas: 4, calcio: 533.3, hierro: 0,  vitaminaa: 0,    vitaminc: 0 },

    { id: 11, name: 'Huevo', image: imagess[10], hdc: 0, proteinas: 12, grasas: 12,  calcio: 54, hierro: 2.5, vitaminaa: 1180,   vitaminc: 0 },

    { id: 12, name: 'Carne vaca', image: imagess[11], hdc: 0, proteinas: 20, grasas: 10, calcio: 4, hierro: 4, vitaminaa: 30,   vitaminc: 0 },

    { id: 13, name: 'Carne cerdo', image: imagess[12], hdc: 0, proteinas: 17, grasas: 21, calcio: 8, hierro: 2, vitaminaa: 0,   vitaminc: 0 },

    { id: 14, name: 'Carne pollo', image: imagess[13], hdc: 0, proteinas: 20, grasas: 6,  calcio: 10, hierro: 1.7,  vitaminaa: 60,  vitaminc: 0 },

    { id: 15, name: 'Carne pescado', image: imagess[14], hdc: 0, proteinas: 18, grasas: 6,  calcio: 0, hierro: 0.7,  vitaminaa: 0,   vitaminc: 0 },

    { id: 16, name: 'Jamón', image: imagess[15], hdc: 0, proteinas: 20, grasas: 15,  calcio: 15, hierro: 7.5, vitaminaa: 0,    vitaminc: 0 },

    { id: 17, name: 'Vegetales A', image: imagess[16], hdc: 5, proteinas: 1, grasas: 0, calcio: 60, hierro: 2.5,  vitaminaa: 2324,   vitaminc: 30 },

    { id: 18, name: 'Vegetales B', image: imagess[17], hdc: 10, proteinas: 1.5, grasas: 0, calcio: 40, hierro: 1.4, vitaminaa: 850,    vitaminc: 16 },
    
    { id: 19, name: 'Vegetales C', image: imagess[18], hdc: 20, proteinas: 2, grasas: 0, calcio: 20, hierro: 1, vitaminaa: 146,    vitaminc: 21 },

    { id: 20, name: 'Frutas frescas', image: imagess[19], hdc: 15, proteinas: 1, grasas: 0, calcio: 18, hierro: 0.4, vitaminaa: 990,    vitaminc: 20 },

    { id: 21, name: 'Frutas desecadas', image: imagess[20], hdc: 64, proteinas: 3, grasas: 1,  calcio: 78, hierro: 3.3, vitaminaa: 164,   vitaminc: 7.8 },

    { id: 22, name: 'Frutas secas', image: imagess[21], hdc: 26, proteinas: 12, grasas: 60, calcio: 131, hierro: 3.8, vitaminaa: 19,   vitaminc: 1.5 },

    { id: 23, name: 'Cereales y derivados', image: imagess[22], hdc: 70, proteinas: 10, grasas: 3, calcio: 27, hierro: 2.3, vitaminaa: 0,    vitaminc: 0 },

    { id: 24, name: 'Legumbres y derivados', image: imagess[23], hdc: 55, proteinas: 22, grasas: 3, calcio: 117, hierro: 7, vitaminaa: 44,   vitaminc: 1.8 },

    { id: 25, name: 'Pan francés', image: imagess[24], hdc: 60, proteinas: 10, grasas: '0',  calcio: 22, hierro: 1.1, vitaminaa: 0,    vitaminc: 0 },

    { id: 26, name: 'Bollo', image: imagess[25], hdc: 50, proteinas: 7, grasas: 23,  calcio: 0.9, hierro: 1.5,  vitaminaa: 0,   vitaminc: 0 },

    { id: 27, name: 'Galletas', image: imagess[26], hdc: 70, proteinas: 11, grasas: 12,  calcio: 0, hierro: 0, vitaminaa: 0,    vitaminc: 0},

    { id: 28, name: 'Azúcar', image: imagess[27], hdc: 100, proteinas: 0, grasas: 0,  calcio: 0, hierro: 0, vitaminaa: 0,    vitaminc: 0 },

    { id: 29, name: 'Dulces', image: imagess[28], hdc: 70, proteinas: 1.5, grasas: 0, calcio: 36, hierro: 0.3,  vitaminaa: 17,  vitaminc: 5 },

    { id: 30, name: 'Aceite', image: imagess[29], hdc: 0, proteinas: 0, grasas: 100,  calcio: 0, hierro: 0,  vitaminaa: 0,    vitaminc: 0 },

    { id: 31, name: 'Manteca', image: imagess[30], hdc: 0, proteinas: 0.5, grasas: 84, calcio: 15, hierro: 0.2 , vitaminaa: 2240,    vitaminc:0 },

    { id: 32, name: 'Crema leche liviana', image: imagess[31], hdc: 4, proteinas: 2.9, grasas: 20,  calcio: 97, hierro: 0.2,  vitaminaa: 840,    vitaminc: 1 },

    { id: 33, name: 'Crema leche espesa', image: imagess[32], hdc: 2.2, proteinas: 1.7, grasas: 44,  calcio: 60, hierro: 0.6,  vitaminaa: 1540,  vitaminc: 1},
  ];

  return (
    <Router>
      <div className='padr3'>
        <Navbar />
        <Routes>
          <Route path="/" element={<Menu data={dataa} />} />
          <Route path="/products/:productId" element={<ProductDetail products={dataa} />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App;